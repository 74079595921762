import { axiosApiInstance } from "@/services/base";

class SearchTermService {
  suggest(payload) {
    let params = { params: payload };
    return axiosApiInstance.get(`search_term/suggest`, params);
  }

  keyword_term(payload) {
    return axiosApiInstance.post(`search_term/keyword_term`, payload);
  }
}

export default new SearchTermService();
