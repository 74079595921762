import { axiosApiInstance } from "@/services/base";

class ProductTrendService {
  search(payload) {
    return axiosApiInstance.post(`product_trend/search`, payload);
  }

  multiple_search(payload) {
    return axiosApiInstance.post(`product_trend/multiple_search`, payload);
  }

  listSavedQuery() {
    return axiosApiInstance.get(`product_trend/saved_queries`);
  }

  createSavedQuery(payload) {
    return axiosApiInstance.post(`product_trend/saved_queries`, payload);
  }

  updateSavedQuery(id, payload) {
    return axiosApiInstance.put(`product_trend/saved_queries/${id}`, payload);
  }

  deleteSavedQuery(id) {
    return axiosApiInstance.delete(`product_trend/saved_queries/${id}`);
  }

  listExcludeList() {
    return axiosApiInstance.get(`product_trend/exclude_lists`);
  }

  showExcludeList(id) {
    return axiosApiInstance.get(`product_trend/exclude_lists/${id}`);
  }

  createExcludeList(payload) {
    return axiosApiInstance.post(`product_trend/exclude_lists`, payload);
  }

  updateExcludeList(id, payload) {
    return axiosApiInstance.put(`product_trend/exclude_lists/${id}`, payload);
  }

  deleteExcludeList(id) {
    return axiosApiInstance.delete(`product_trend/exclude_lists/${id}`);
  }

  addWordsToExcludeList(id, payload) {
    return axiosApiInstance.put(
      `product_trend/exclude_lists/${id}/add_words`,
      payload
    );
  }
}

export default new ProductTrendService();
