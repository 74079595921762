<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <Card class="main-content">
        <template #content>
          <!-- First Row -->
          <div class="condition-row-wrapper">
            <div style="flex-grow: 9">
              <div class="condition-title">
                Keyword Search
                <i
                  class="pi pi-info-circle"
                  v-tooltip="
                    'Search for multiple keywords, separate words with spaces'
                  "
                ></i>
              </div>
              <div>
                <AutoComplete
                  v-model="searchState.term"
                  class="w-full"
                  inputClass="w-full"
                  :suggestions="searchTermSuggestions"
                  @complete="searchSuggest($event)"
                />
              </div>
            </div>
            <div style="flex-grow: 1; margin-left: 0.5rem">
              <div class="condition-title">Search Type</div>
              <div>
                <Dropdown
                  v-model="searchState.config.terms_search_mode"
                  :options="[
                    { label: 'OR', value: '1' },
                    { label: 'AND', value: '2' },
                  ]"
                  optionLabel="label"
                  optionValue="value"
                />
              </div>
            </div>
            <div style="flex-grow: 1; margin-left: 0.5rem">
              <div class="condition-title">
                Word Count
                <i
                  class="pi pi-info-circle"
                  v-tooltip="'keyword word count'"
                ></i>
              </div>
              <div style="display: flex">
                <div class="mr-2">
                  <InputNumber
                    v-model="searchState.word_count.gte"
                    mode="decimal"
                    :step="1"
                    placeholder="Min"
                    class="condition-inputnumber"
                  />
                </div>
                <div>
                  <InputNumber
                    v-model="searchState.word_count.lte"
                    mode="decimal"
                    :step="1"
                    placeholder="Max"
                    class="condition-inputnumber"
                  />
                </div>
              </div>
            </div>
            <div style="flex-grow: 1; margin-left: 0.5rem">
              <div class="condition-title">
                Occurrence
                <i
                  class="pi pi-info-circle"
                  v-tooltip="
                    'The number of times all the radicals of the keyword have appeared in all the data (can be used to evaluate the popularity of this keyword in the current period)'
                  "
                ></i>
              </div>
              <div style="display: flex">
                <div class="mr-2">
                  <InputNumber
                    v-model="searchState.occurrence.gte"
                    mode="decimal"
                    :step="1"
                    placeholder="Min"
                    class="condition-inputnumber"
                  />
                </div>
                <div>
                  <InputNumber
                    v-model="searchState.occurrence.lte"
                    mode="decimal"
                    :step="1"
                    placeholder="Max"
                    class="condition-inputnumber"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Second Row -->
          <div style="margin-top: 1rem">
            <div style="display: flex; justify-content: space-between">
              <div class="condition-title pt-3">
                Exclude Words
                <i class="pi pi-info-circle" v-tooltip="'Split by comma ,'"></i>
              </div>
              <div>
                <Button
                  class="p-button-link"
                  @click="handleShowAddToExcludeListDialog"
                >
                  Add to Exclude List
                </Button>
              </div>
            </div>
            <div>
              <InputText
                v-model="searchState.exclude_words"
                placeholder="eg: iPhone, Samsung, Apple"
                style="width: 100%"
              />
            </div>
          </div>

          <!-- Third Row -->
          <div style="margin-top: 1rem">
            <div class="condition-title">
              Exclude Lists
              <i
                class="pi pi-info-circle"
                v-tooltip="'Exclude keywords within lists'"
              ></i>
            </div>
            <div>
              <MultiSelect
                v-model="searchState.exclude_list_ids"
                :options="excludeListsOptions"
                :showToggleAll="false"
                optionLabel="label"
                optionValue="value"
                placeholder="Select exclude lists"
                display="chip"
                style="width: 100%"
              />
            </div>
          </div>

          <!-- Advanced Filter & Sorting -->
          <div style="margin-top: 1rem">
            <Accordion>
              <AccordionTab>
                <template #header>
                  <i class="pi pi-filter"></i>
                  <span style="margin-left: 0.5rem">
                    {{ "Advanced Filter & Sorting" }}
                  </span>
                </template>

                <!-- Row 1 -->
                <div class="condition-row-wrapper">
                  <div style="flex-grow: 1">
                    <div class="condition-title">
                      Search Volume Rank
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="'Limit the range of Search Volume Rank'"
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.sfr.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.sfr.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-grow: 1; margin-left: 0.5rem">
                    <div class="condition-title">
                      Tendency
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'Up to now or several consecutive periods of decline'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.sfr_persistence_tendency.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.sfr_persistence_tendency.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-grow: 1; margin-left: 0.5rem">
                    <div class="condition-title">
                      Conversion Share
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'The percentage of the total sales of the top three products in the keyword sales accounted for the total sales of the keyword can be used to filter the exclusive market (for example, fill in 50 for max, which means that the top three sales of this keyword can only account for 50% of the total sales.)'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.total_conversion_share.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.total_conversion_share.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-grow: 1; margin-left: 0.5rem">
                    <div class="condition-title">
                      Click Share
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'Keyword clicks The percentage of the total clicks of the top three products in the total clicks of the keyword can be used to filter the exclusive market (for example, fill in 50 for max, which means that the top three clicks of this keyword account for only 50% of the total clicks)'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.total_click_share.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.total_click_share.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row 2 -->
                <div class="condition-row-wrapper" style="margin-top: 1rem">
                  <div style="flex-grow: 1">
                    <div class="condition-title">
                      Compare to Last Year
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'Compared with the same period of last year, the variable of Search Volume Rank (Note 1: If there is no such keyword data in the same period of last year, it will be a null value | Note 2: Since the past variable is subtracted from the present, -300 means that the Search Volume Rank has increased compared to the same period of last year Ranked 300)'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.sfr_compare_period.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.sfr_compare_period.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-grow: 1; margin-left: 0.5rem">
                    <div class="condition-title">
                      Compare to Average
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'Compared with the average of the past year, the variable of Search Volume Rank (Note 1: Since the past variable is subtracted from the present, -300 means that the ranking has increased by 300 compared with the average Search Volume Rank of the past year'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.sfr_compare_average.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.sfr_compare_average.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-grow: 1; margin-left: 0.5rem">
                    <div class="condition-title">
                      Recent Change
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'The Search Volume Rank variables of the current period and the past three periods (Note 1: Since the past variables are subtracted from the present, -300 means that the ranking has increased by 300 compared with the Search Volume Rank of the previous three periods'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.sfr_changes.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.sfr_changes.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-grow: 1; margin-left: 0.5rem">
                    <div class="condition-title">
                      Volatility
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'The historical volatility of the past year (Note 1: The higher the period, the greater the volatility of the ranking)'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.sfr_volatility.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.sfr_volatility.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row 3 -->
                <div class="condition-row-wrapper" style="margin-top: 1rem">
                  <div style="flex-grow: 1">
                    <div class="condition-title">
                      Proportion
                      <i
                        class="pi pi-info-circle"
                        v-tooltip="
                          'The largest Search Volume Rank in the past year (i.e. worst) divided by the smallest Search Volume Rank in the past year (i.e. best)'
                        "
                      ></i>
                    </div>
                    <div style="display: flex">
                      <div class="mr-2">
                        <InputNumber
                          v-model="searchState.sfr_proportion.gte"
                          mode="decimal"
                          :step="1"
                          placeholder="Min"
                          class="condition-inputnumber"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="searchState.sfr_proportion.lte"
                          mode="decimal"
                          :step="1"
                          placeholder="Max"
                          class="condition-inputnumber"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row 4 Sorting -->
                <div style="margin-top: 1rem">
                  <div class="condition-title">
                    Sorting
                    <i
                      class="pi pi-info-circle"
                      v-tooltip="
                        'Set multiple column sorting conditions (ex: If you want to sort by Tendency first, but you want to sort by Word Count in the same situation as Tendency, then set #1 as Tendency, #2 as Word Count), ASC from small to Large, DESC is from large to small, and up to six sorting conditions can be set, and the first condition will prevail if the field is repeated'
                      "
                    ></i>
                  </div>
                  <DataTable
                    :value="searchState.sorting"
                    @rowReorder="onRowReorder"
                  >
                    <Column :rowReorder="true" headerStyle="width: 3rem" />
                    <Column field="field" header="Field">
                      <template #body="{ index }">
                        <Dropdown
                          v-model="searchState.sorting[index].field"
                          :options="sortFieldOptions"
                          optionLabel="label"
                          optionValue="value"
                        />
                      </template>
                    </Column>
                    <Column field="sorting" header="Order">
                      <template #body="{ index }">
                        <Dropdown
                          v-model="searchState.sorting[index].sorting"
                          :options="['asc', 'desc']"
                        />
                      </template>
                    </Column>
                    <Column header="Action" columnKey="action">
                      <template #body="{ index }">
                        <Button
                          icon="pi pi-times"
                          class="p-button-rounded p-button-text p-button-plain"
                          @click="deleteSorting(index)"
                        />
                      </template>
                    </Column>
                  </DataTable>
                  <Button class="p-button-link" @click="addSort">
                    Add Sort
                  </Button>
                </div>
              </AccordionTab>
            </Accordion>
          </div>

          <div>
            <p class="text-pink-700 font-semibold">
              Note: The calculation rules for SVR have changed after
              2023-05-14.
            </p>
          </div>
          <div style="margin-top: 1rem; text-align: right">
            <Button
              class="p-button-outlined p-button-secondary"
              style="margin-right: 0.5rem"
              @click="handleShowSaveQueryDialog"
            >
              Save Query
            </Button>
            <Button class="handleSearch" @click="handleSearch(1)">
              Search
            </Button>
          </div>

          <!-- Save to Query Dialog -->
          <Dialog header="Save Query" v-model:visible="showSaveQueryDialog">
            <div>
              Note
              <InputText type="text" v-model="note" style="width: 100%" />
            </div>

            <template #footer>
              <Button
                label="Cancel"
                icon="pi pi-times"
                @click="handleHideSaveQueryDialog"
                class="p-button-text"
              />
              <Button
                label="Save"
                icon="pi pi-check"
                @click="saveQuery"
                autofocus
              />
            </template>
          </Dialog>

          <!-- Add to Exclude List -->
          <Dialog
            header="Add words to Exclude List"
            v-model:visible="showAddToExcludeListDialog"
          >
            <div>
              Exclude List
              <Dropdown
                v-model="addToExcludeListId"
                :options="excludeListsOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select exclude list"
                style="width: 100%"
              />
            </div>

            <template #footer>
              <Button
                label="Cancel"
                icon="pi pi-times"
                @click="handleHideAddToExcludeListDialog"
                class="p-button-text"
              />
              <Button
                label="Save"
                icon="pi pi-check"
                @click="addExcludeWordsToList"
                autofocus
              />
            </template>
          </Dialog>
        </template>
      </Card>

      <Card class="main-content">
        <template #content>
          <ProgressBar
            mode="indeterminate"
            style="height: 0.5em"
            v-if="loading"
          />

          <BlockUI :blocked="loading">
            <DataTable :value="dataSource" responsiveLayout="scroll">
              <Column field="keyword" header="Keyword">
                <template #body="{ data }">
                  <a
                    :href="searchOnAmzUrl(data.keyword)"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {{ data.keyword }}
                  </a>
                </template>
              </Column>
              <Column field="svr" header="SVR">
                <template #body="{ data }">
                  {{ data.sfr }}
                </template>
              </Column>
              <Column field="top_3_asins" header="Top 3 ASINs">
                <template #body="{ data }">
                  <div style="display: flex">
                    <div
                      v-for="asin in data.top_3_asins"
                      :key="asin.Asin"
                      style="padding: 0.5rem"
                    >
                      <div>
                        <a
                          :href="listingUrl(asin.Asin)"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {{ asin.Asin }}
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="trend_graph" header="Trend Graph">
                <template #body="{ data }">
                  <div style="display: flex">
                    <Chart
                      type="line"
                      :data="renderTrendGraph(data.chart_data, 'Sfr')"
                      :options="SVRTrendGraphOptions"
                    />
                    <Chart
                      type="line"
                      :data="
                        renderTrendGraph(
                          data.chart_data,
                          'TotalConversionShare'
                        )
                      "
                      :options="trendGraphOptions('Top3 Conv. Share')"
                    />
                    <Chart
                      type="line"
                      :data="
                        renderTrendGraph(data.chart_data, 'TotalClickShare')
                      "
                      :options="trendGraphOptions('Top3 Click Share')"
                    />
                  </div>
                </template>
              </Column>
              <Column field="tendency" header="Tendency">
                <template #body="{ data }">
                  <div :class="renderTendencyColor(data.tendency)">
                    {{ renderTendency(data.tendency) }}
                  </div>
                </template>
              </Column>
              <Column
                field="compare_to_prev_period"
                header="Compare to Prev. Period"
              >
                <template #body="{ data }">
                  <div :class="renderCompareColor(data.compare_to_prev_period)">
                    {{ renderCompare(data.compare_to_prev_period) }}
                  </div>
                </template>
              </Column>
              <Column
                field="compare_to_last_year"
                header="Compare to Last Year"
              >
                <template #body="{ data }">
                  <div :class="renderCompareColor(data.compare_to_last_year)">
                    {{ renderCompare(data.compare_to_last_year) }}
                  </div>
                </template>
              </Column>
              <Column field="top_3_conv_share" header="Top 3 Conv. Share">
                <template #body="{ data }">
                  {{ sumConversionShare(data.top_3_asins) }}
                </template>
              </Column>
              <Column field="occurrence" header="Occurrence" />
            </DataTable>
            <Paginator
              :rows="20"
              :totalRecords="totalCount"
              @page="changePage"
            />
          </BlockUI>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AutoComplete from "primevue/autocomplete";
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Card from "primevue/card";
import Chart from "primevue/chart";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Paginator from "primevue/paginator";
import ProgressBar from "primevue/progressbar";

import ProductTrendService from "@/services/product_trend_service";
import SearchTermService from "@/services/search_term_service";

import store from "@/store";
import { formatNumber } from "@/utils/formatter";

export default defineComponent({
  components: {
    Accordion,
    AccordionTab,
    AutoComplete,
    BlockUI,
    Button,
    Card,
    Chart,
    Column,
    DataTable,
    Dialog,
    Dropdown,
    InputNumber,
    InputText,
    MultiSelect,
    Paginator,
    ProgressBar,
  },

  setup() {
    onMounted(() => {
      let savedQuery = store.getters.savedQuery;
      if (savedQuery) {
        searchState.term = savedQuery.term;
        searchState.department = savedQuery.department;
        searchState.exclude_categories = savedQuery.exclude_categories;
        searchState.word_count = savedQuery.word_count;
        searchState.occurrence = savedQuery.occurrence;
        searchState.exclude_words = savedQuery.exclude_words;
        searchState.exclude_list_ids = savedQuery.exclude_list_ids;
        searchState.total_conversion_share = savedQuery.total_conversion_share;
        searchState.total_click_share = savedQuery.total_click_share;
        searchState.average_margin = savedQuery.average_margin;
        searchState.margin_variation = savedQuery.margin_variation;
        searchState.average_fba_fee = savedQuery.average_fba_fee;
        searchState.fba_fee_variation = savedQuery.fba_fee_variation;
        searchState.average_price = savedQuery.average_price;
        searchState.price_variation = savedQuery.price_variation;
        searchState.average_roi = savedQuery.average_roi;
        searchState.average_net = savedQuery.average_net;
        searchState.sfr_compare_period = savedQuery.sfr_compare_period;
        searchState.sfr_compare_average = savedQuery.sfr_compare_average;
        searchState.sfr_changes = savedQuery.sfr_changes;
        searchState.sfr_persistence_tendency =
          savedQuery.sfr_persistence_tendency;
        searchState.sfr_volatility = savedQuery.sfr_volatility;
        searchState.sfr_proportion = savedQuery.sfr_proportion;
        searchState.sfr = savedQuery.sfr;
        searchState.config = savedQuery.config;
        searchState.sorting = savedQuery.sorting;

        handleSearch(1);
        store.dispatch("setSavedQuery", null);
      }

      ProductTrendService.listExcludeList()
        .then(({ data }) => {
          excludeListsOptions.value = data.exclude_lists.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    });

    const getLast2Sunday = () => {
      let today = new Date();

      let thisSunday = new Date(
        today.setDate(today.getDate() - today.getDay())
      );

      // We almost imported the latest data in Thursday
      let gap = today.getDay() === 3 ? 7 : 14;

      let last2Sunday = new Date(thisSunday - gap * 86400 * 1000);
      return last2Sunday.toLocaleDateString("fr-CA");
    };

    const toast = useToast();

    const blocked = ref(false);
    const loading = ref(false);
    const searchTermSuggestions = ref([]);
    const searchState = reactive({
      term: null,
      date: getLast2Sunday(),
      department: "1",
      exclude_categories: [],
      word_count: {
        gte: null,
        lte: null,
      },
      occurrence: {
        gte: null,
        lte: null,
      },
      exclude_words: "",
      exclude_list_ids: [],
      total_conversion_share: {
        gte: null,
        lte: null,
      },
      total_click_share: {
        gte: null,
        lte: null,
      },
      average_margin: {
        gte: null,
        lte: null,
      },
      margin_variation: {
        gte: null,
        lte: null,
      },
      average_fba_fee: {
        gte: null,
        lte: null,
      },
      fba_fee_variation: {
        gte: null,
        lte: null,
      },
      average_price: {
        gte: null,
        lte: null,
      },
      price_variation: {
        gte: null,
        lte: null,
      },
      average_roi: {
        gte: null,
        lte: null,
      },
      average_net: {
        gte: null,
        lte: null,
      },
      sfr_compare_period: {
        gte: null,
        lte: null,
      },
      sfr_compare_average: {
        gte: null,
        lte: null,
      },
      sfr_changes: {
        gte: null,
        lte: null,
      },
      sfr_persistence_tendency: {
        gte: null,
        lte: null,
      },
      sfr_volatility: {
        gte: null,
        lte: null,
      },
      sfr_proportion: {
        gte: null,
        lte: null,
      },
      sfr: {
        gte: null,
        lte: null,
      },
      config: {
        terms_search_mode: "1",
      },
      page: 1,
      sorting: [
        {
          field: "sfr",
          sorting: "asc",
          priority: 1,
        },
      ],
    });

    const excludeListsOptions = ref([]);
    const sortFieldOptions = [
      { label: "SVR", value: "sfr" },
      { label: "Word Count", value: "word_count" },
      { label: "Occurrence", value: "occurrence" },
      { label: "Conversion Share", value: "total_conversion_share" },
      { label: "Compare to Prev Period", value: "sfr_changes" },
      { label: "Compare to Last Year", value: "sfr_compare_period" },
      { label: "Compare to Average", value: "sfr_compare_average" },
      { label: "Tendency", value: "sfr_persistence_tendency" },
      { label: "Search Score", value: "score" },
    ];

    const dataSource = ref([]);
    const totalCount = ref(0);

    const handleSearch = (page = 1) => {
      loading.value = true;

      console.info(`page -> ${page}`);

      searchState.page = page;

      // Scroll to Top
      window.scrollTo(0, 0);

      ProductTrendService.search(searchState)
        .then(({ data }) => {
          totalCount.value = data.total;
          dataSource.value = data.result;
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Product Trend",
            detail: "Search fail",
            life: 3000,
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const changePage = (event) => {
      handleSearch(event.page + 1);
    };

    const searchOnAmzUrl = (keyword) => {
      const encodedKeyword = keyword.replaceAll(" ", "+");
      return `https://www.amazon.com/s?k=${encodedKeyword}&ref=nb_sb_noss`;
    };

    const listingUrl = (asin) => {
      return `https://www.amazon.com/dp/${asin}`;
    };

    const renderTendency = (tendency) => {
      if (tendency === null) {
        return "-";
      }

      if (tendency > 0) {
        return `⤴${formatNumber(tendency)}`;
      } else if (tendency < 0) {
        return `⤵${formatNumber(tendency * -1)}`;
      } else {
        return tendency;
      }
    };

    const renderTendencyColor = (tendency) => {
      if (tendency > 0) {
        return "text-green";
      } else if (tendency < 0) {
        return "text-red";
      } else {
        return "text-gray";
      }
    };

    const renderCompare = (diff) => {
      if (diff === null) {
        return "-";
      }

      if (diff > 0) {
        return `↓${formatNumber(diff)}`;
      } else if (diff < 0) {
        return `↑${formatNumber(diff * -1)}`;
      } else {
        return diff;
      }
    };

    const renderCompareColor = (diff) => {
      if (diff === null) {
        return "text-gray";
      }

      if (diff > 0) {
        return "text-red";
      } else if (diff < 0) {
        return "text-green";
      } else {
        return "text-gray";
      }
    };

    const sumConversionShare = (asins) => {
      let sum = asins.reduce((pV, cV) => {
        return pV + cV.ConversionShare;
      }, 0);

      return `${formatNumber(sum)} %`;
    };

    const SVRTrendGraphOptions = {
      plugins: {
        legend: {
          display: false, // hide the legend
        },
        title: {
          display: true,
          text: "SVR",
          position: "bottom",
        },
      },
      scales: {
        y: {
          reverse: true, // reverse y axis
        },
      },
    };

    const trendGraphOptions = (title) => {
      return {
        plugins: {
          legend: {
            display: false, // hide the legend
          },
          title: {
            display: true,
            text: title,
            position: "bottom",
          },
        },
      };
    };

    const renderTrendGraph = (chartData, field) => {
      let lineData = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: "#42A5F5",
          },
        ],
      };

      chartData.forEach((element) => {
        lineData.labels.push(element.PartitionDate);
        lineData.datasets[0].data.push(element[field]);
      });

      return lineData;
    };

    const showSaveQueryDialog = ref(false);
    const note = ref("");
    const handleShowSaveQueryDialog = () => {
      note.value = "";
      showSaveQueryDialog.value = true;
    };

    const handleHideSaveQueryDialog = () => {
      showSaveQueryDialog.value = false;
    };

    const saveQuery = () => {
      let payload = {
        saved_query: {
          note: note.value,
          query: searchState,
        },
      };

      blocked.value = true;
      ProductTrendService.createSavedQuery(payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Product Trend",
            detail: "Saved query",
            life: 1000,
          });
        })
        .catch((e) => {
          console.error(e);

          toast.add({
            severity: "error",
            summary: "Product Trend",
            detail: "Save query fail",
            life: 3000,
          });
        })
        .finally(() => {
          showSaveQueryDialog.value = false;
          blocked.value = false;
        });
    };

    const deleteSorting = (index) => {
      searchState.sorting.splice(index, 1);
      refreshSort();
    };

    const addSort = () => {
      searchState.sorting.push({
        field: "sfr",
        sorting: "asc",
        priority: searchState.sorting.length + 1,
      });
    };

    const refreshSort = () => {
      searchState.sorting.forEach((elm, index) => {
        elm.priority = index + 1;
      });
    };

    const onRowReorder = (event) => {
      searchState.sorting = event.value;

      refreshSort();
    };

    const showAddToExcludeListDialog = ref(false);
    const addToExcludeListId = ref(null);
    const handleShowAddToExcludeListDialog = () => {
      addToExcludeListId.value = null;
      showAddToExcludeListDialog.value = true;
    };
    const handleHideAddToExcludeListDialog = () => {
      showAddToExcludeListDialog.value = false;
    };

    const addExcludeWordsToList = () => {
      if (addToExcludeListId.value === null) {
        toast.add({
          severity: "warn",
          summary: "Product Trend",
          detail: "No exclude list selected",
          life: 3000,
        });

        return;
      }

      let exclude_words = searchState.exclude_words
        .split(",")
        .map((s) => s.trim())
        .filter((s) => s !== "");

      let payload = {
        exclude_words: [...new Set(exclude_words)],
      };

      blocked.value = true;
      ProductTrendService.addWordsToExcludeList(
        addToExcludeListId.value,
        payload
      )
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Product Trend",
            detail: "Added words to exclude list",
            life: 1000,
          });
        })
        .catch((e) => {
          console.error(e);

          toast.add({
            severity: "error",
            summary: "Product Trend",
            detail: "Added words fail",
            life: 3000,
          });
        })
        .finally(() => {
          showAddToExcludeListDialog.value = false;
          blocked.value = false;
        });
    };

    const searchSuggest = (event) => {
      let term = { term: event.query.trim() };
      SearchTermService.suggest(term)
        .then(({ data }) => {
          searchTermSuggestions.value = data;
        })
        .catch((e) => {
          console.error(e);
        });
    };

    return {
      blocked,
      loading,
      searchTermSuggestions,
      searchState,
      excludeListsOptions,
      sortFieldOptions,
      dataSource,
      totalCount,
      handleSearch,
      changePage,

      searchOnAmzUrl,
      listingUrl,

      renderTendency,
      renderTendencyColor,
      renderCompare,
      renderCompareColor,
      sumConversionShare,

      SVRTrendGraphOptions,
      trendGraphOptions,
      renderTrendGraph,

      showSaveQueryDialog,
      note,
      handleShowSaveQueryDialog,
      handleHideSaveQueryDialog,
      saveQuery,

      deleteSorting,
      addSort,
      onRowReorder,

      showAddToExcludeListDialog,
      addToExcludeListId,
      handleShowAddToExcludeListDialog,
      handleHideAddToExcludeListDialog,
      addExcludeWordsToList,

      searchSuggest,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-content {
  margin-top: 1rem;
}

.condition-row-wrapper {
  display: flex;
}

.condition-title {
  font-weight: 500;
}

.condition-inputnumber {
  ::v-deep .p-inputnumber-input {
    width: 100px;
  }
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.text-gray {
  color: gray;
}
</style>
